import {
  requiredText,
  email,
  urlText,
  phoneText,
  phoneTextRequired,
  alphaNumeric,
  alphaNumericLength,
  onlyText,
  matchPassword,
  requiredOnlyText,
  nameText,
  emailText,
  emailReqText,
  urlTextOnly,
  requiredTextWithNoZero,
  emailTextNotReq,
  multipleEmailTextNotReq
} from '../genericSchemas';
import * as Yup from 'yup';
export const provisionFormSchemas = Yup.object().shape({
  companyName: requiredText,
  companyKeyRequest: null,
  status: null,
  accountManager: email,
  accountManagerEmail: email,
  leadEmails: emailReqText,
  url: urlTextOnly,
  googleAnalyticsCode: null,
  crmName: null,
  crmEmailAddress: emailTextNotReq,
  notes: null,
  provisionedCompanyId: Yup.number().when('status', {
    is: value => {
      return value == 4;
    },
    then: requiredTextWithNoZero(),
    otherwise: null
  }),
  primaryCategoryId: requiredTextWithNoZero(),
  secondaryCategories: null,
  pricing: null,
  billingEmailAddress: multipleEmailTextNotReq,
  hasSetupFee: null,
  hasSmsFee: null,
  hasExitPopupFee: null,
  hasFacebookFee: null,
  hasSpanishFee: null,
  useCrmEmail: null,
  autoDealerCRMEmail: emailTextNotReq,
  freeConsultation: null,
  consultationFee: null,
  areaOfCoverage: null,
  linkToPrivacyPolicy: urlTextOnly,
  scriptTitlePosition: null,
  hipaa: null,
  languages: null,
  callConnect: null,
  quickConnectTranscript: null,
  callConnectPhone: phoneText,
  insuranceCoverageResponse: null,
  specialInstructions: null,
  officeHoursTimezone: null
});

export const basicDetailSchemas = Yup.object().shape({
  companyName: requiredText,
  companyKeyRequest: null,
  status: null,
  accountManager: email,
  accountManagerEmail: email,
  leadEmails: emailReqText,
  billingEmailAddress: multipleEmailTextNotReq,
  url: urlTextOnly,
  googleAnalyticsCode: null,
  crmName: null,
  crmEmailAddress: emailTextNotReq,
  notes: null,
  // provisionedCompanyId: null,
  provisionedCompanyId: Yup.number().when('status', {
    is: value => {
      return value == 4;
    },
    then: requiredTextWithNoZero(),
    otherwise: null
  }),
  primaryCategoryId: null,
  secondaryCategories: null
});

export const fileAttachementSchema = Yup.object().shape({
  attachements: null
});

export const pricingDetailSchemas = Yup.object().shape({
  pricing: null,
  hasSetupFee: null,
  hasSmsFee: null,
  hasExitPopUpFee: null,
  hasFacebookFee: null,
  hasSpanishFee: null,
  useCrmEmail: null,
  autoDealerCRMEmail: emailTextNotReq,
  freeConsultation: null,
  consultationFee: null
});

export const featureDetailSchemas = Yup.object().shape({
  features: null
});

export const BusinessOperationSchemas = Yup.object().shape({
  areaOfCoverage: null,
  linkToPrivacyPolicy: urlTextOnly,
  scriptTitlePosition: null,
  hipaa: null,
  languages: null,
  callConnect: null,
  quickConnectTranscript: null,
  callConnectPhone: phoneText,
  insuranceCoverageResponse: null,
  specialInstructions: null,
  officeHoursTimezone: null
});
