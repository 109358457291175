import { AppMetaProvider } from 'components/Sidebar/helper';

const MediaType = { Video: 'Video', Image: 'Image' };

const getImageURL = (path = '') => {
  let theme = AppMetaProvider().GetCurrentTheme() || 'blue';
  let updatedPath = path.replace('<#=theme#>', theme);
  return updatedPath;
};

const checkType = path => {
  if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)) {
    return MediaType.Image;
  } else {
    return MediaType.Video;
  }
};

export const MediaComp = ({ path }) => {
  const generateMediaTag = path => {
    switch (checkType(path)) {
      case MediaType.Video:
        return (
          <video
            className="w-100"
            controls
            autoPlay
            preload="auther"
            style={{ borderRadius: '5px' }}
          >
            <source src={getImageURL(path)} type="video/mp4"></source>
          </video>
        );
      case MediaType.Image:
        return (
          <img
            className="w-100"
            src={getImageURL(path)}
            style={{ borderRadius: '5px' }}
            alt=""
          />
        );
      default:
        <></>;
    }
  };
  return <>{generateMediaTag(path)}</>;
};
