import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Container, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { UilExclamationTriangle } from '@iconscout/react-unicons';
import Chats from './Chats';
import UserSuggestions from './UserSuggestions';
import config from 'config';
// import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import ChatsMobile from './ChatsMobile';
import ChatWrapper from './ChatWrapper';
import connectionService from 'helpers/connectionIdentifier';
import { useDispatch, useSelector } from 'react-redux';
import DisabledMessaging from './disabledMD';
import MDSignUpPage from './MDSignUp';
import { selectRoles } from 'features/roles/rolesSlice';
import {
  clearSelectedChatUnreadMessageCount,
  getSelectedChatId,
  setEnableNotificationSound
} from 'features/messagingChats/messagingChats';
import storage from 'helpers/storage';
import { logIt } from 'helpers/logger';
import { renderMessagingComponent } from 'helpers/messaging/message.methods';
import { getUserFromViewedUser } from 'helpers/generic.methods';
import { setAutoSuggestEnable } from 'features/messagingChats/suggestions';
import { toggleChatDetailsRightPane } from 'features/chat/chatSlice';
const Messaging = props => {
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [toggleChatDetail, setToggleChatDetail] = useState(false);
  const [toggleActiveChats, setToggleActiveChats] = useState(false);
  const [toggleChatSuggestion, setToggleChatSuggestion] = useState(false);
  const [defaultSuggestionTab, setDefaultSuggestionTab] = useState(false);
  const [defaultMessageText, setDefaultMessageText] = useState('');

  const dispatch = useDispatch();

  const { roles } = useSelector(selectRoles);
  let selectedChatId = useSelector(getSelectedChatId);

  useEffect(() => {
    let url = `${config?.apiWCFUrl?.replace(
      'Services/ApexChatService.svc/',
      ''
    )}request.ashx`;
    let connectionServiceConfig = {
      uniqueKey: 'SetupConnectionStatusOnLoad',
      interval: 2000, // 2sec or 2000ms
      url: url,
      // url: window.location.origin + '/meta.json',
      retryCount: 3, // considering number of request fail count, for example: 3 request fails will be considered that network is down
      onReconnect: () => logIt('log', 'reconnecting...'),
      onFailure: () => logIt('log', 'connection failed...'),
      // we may provide it templates as well but its not required in our case as we just need to show/hide in callback
      onSuccess: function (html, status) {
        logIt('log', 'status', status);
        if (!status) {
          setConnectionStatus(true);
        } else {
          setConnectionStatus(false);
        }
      }
    };

    if (
      roles?.messaging?.allowMessagingAccess &&
      roles?.messaging?.chatOperations?.status
      // && !window.RTC.status
    ) {
      connectionService.Init(connectionServiceConfig);
    }

    return () => {
      connectionService.ClearAll();
    };
  }, []);
  const { t } = useTranslation();

  useEffect(() => {
    let user = getUserFromViewedUser();
    if ('autoSuggestEnable' in user) {
      dispatch(setAutoSuggestEnable(user?.autoSuggestEnable));
    }

    // set initial sound state, enabled or disabled based on localstorage value
    let soundFlag = user?.enableSound;
    if (soundFlag !== null && soundFlag !== undefined) {
      dispatch(setEnableNotificationSound(soundFlag));
    }

    //Note: if selected chat exist then reset selected chat unread message count
    //when page change and get back to MD again
    if (selectedChatId) {
      dispatch(clearSelectedChatUnreadMessageCount(selectedChatId));
    }

    return () => {
      // close user detail & suggestion panel on unmount
      dispatch(
        toggleChatDetailsRightPane({
          show: false
        })
      );
    };
  }, []);

  const MDWrapper = () => (
    <ChatWrapper chatOperations={roles?.messaging?.chatOperations}>
      <Container fluid className="container-full-height">
        <Modal
          show={connectionStatus}
          backdrop={true}
          keyboard={false}
          centered
        >
          <Modal.Header className="d-flex justify-content-center align-items-center bg-dark">
            <UilExclamationTriangle className="text-light mr-2 mb-2" />
            <h4 className="text-light">Something went wrong.</h4>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>
                There might be a problem with your internet connection or the
                server is not responding at the moment. Please wait while the
                system is trying to reconnect.
                <Spinner
                  className="ml-2"
                  animation="border"
                  size="sm"
                  as="span"
                />
              </p>
            </div>
          </Modal.Body>
        </Modal>
        <Row
          className={`g-4 msg-calc-height overflow-hidden ${
            isMobile ? 'messaging-mobile' : 'messaging-desktop'
          }`}
        >
          {/* xs={1} md={2} */}
          <Chats
            toggleChatDetail={toggleChatDetail}
            setToggleChatDetail={setToggleChatDetail}
            toggleActiveChats={toggleActiveChats}
            setToggleActiveChats={setToggleActiveChats}
            setToggleChatSuggestion={setToggleChatSuggestion}
            setDefaultSuggestionTab={setDefaultSuggestionTab}
            defaultMessageText={defaultMessageText}
          />
          {/* {toggleChatSuggestion && ( */}
          {toggleChatDetail && (
            <UserSuggestions
              setToggleChatSuggestion={setToggleChatSuggestion}
              setToggleActiveChats={setToggleActiveChats}
              setToggleChatDetail={setToggleChatDetail}
              defaultSuggestionTab={defaultSuggestionTab}
              setDefaultMessageText={setDefaultMessageText}
            />
          )}
          {/* )} */}
        </Row>
      </Container>
    </ChatWrapper>
  );

  return renderMessagingComponent(
    MDWrapper(),
    <MDSignUpPage />,
    <MDSignUpPage showForm={false} />,
    roles?.messaging?.selectedComponentType
  );
};

export default Messaging;
