import makeStyles from '@material-ui/core/styles/makeStyles';
import HelpComp from 'components/Sidebar/helpComp';
import Logo from 'components/Sidebar/Logo';
import ProfileNav from 'components/Sidebar/ProfileNav';
import { Link } from 'react-router-dom';
import UilBars from '@iconscout/react-unicons/icons/uil-bars';
import useViewport from 'helpers/viewport-hook';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLoadedScript,
  setScript
} from 'features/script-load/scriptLoadSlice';
import { useState } from 'react';
import { getUserFromViewedUser } from 'helpers/generic.methods';
import storage from 'helpers/storage';
import config from 'config';

const MainDivChatWidget = 'apexchat_prechat_invitation_wrapper';
const MainDivChatWindow = 'apexchat_dompopup_chatwindow_wrapper';
const IFramIdWidget = 'apexchat_prechat_invitation_frame';
const IFramIdChatBox = 'apexchat_dompopup_chatwindow_frame';
const classNameChatBoxCloseButton = 'livechat_close';
const ElementIdWidgetButton = 'apexchat_prechat_main_bg';
const ElementIdPreChat = 'apexchat_prechat_message_popup_container';

const useStyles = makeStyles(theme => ({
  topContainer: {
    background: 'white',
    padding: '10px',
    position: 'fixed',
    width: '-webkit-fill-available',
    color: 'black',
    zIndex: '1041',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '70px'
  },
  logoLink: {
    background: 'var(--primary-4)',
    borderRadius: '10px'
  },
  topbarMenuContainer: { display: 'grid', gridAutoFlow: 'column', gap: '20px' }
}));

const TopBar = ({ showSidebar, setShowSidebar }) => {
  const classes = useStyles();
  const screen = useViewport();
  const dispatch = useDispatch();

  const { scriptLoad } = useSelector(selectLoadedScript);
  const [showChat, setShowChat] = useState(false);
  const [loginDetails, setLoginDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (JSON.parse(scriptLoad) && window.navigator.onLine) {
      loadScriptFunc();
    }
  }, []);
  useEffect(() => {
    let loggedInUser = getUserFromViewedUser();
    if (loggedInUser) {
      setLoginDetails(loggedInUser);
    }
  }, [showChat]);
  const showWidget = () => {
    setShowChat(true);
    widgetVisibility(MainDivChatWidget, true);
    widgetVisibility(ElementIdWidgetButton, true);
    widgetVisibility(ElementIdPreChat, true);
  };

  const initializeChatCloseOnClick = () => {
    let iframeChat = document.getElementById(IFramIdChatBox);
    if (iframeChat) {
      let iframeDoc = (iframeChat.contentWindow || iframeChat.contentDocument)
        .document;
      let chatCloseButtons = iframeDoc.getElementsByClassName(
        classNameChatBoxCloseButton
      );
      if (chatCloseButtons.length) {
        chatCloseButtons[0].onclick = chatCloseFunction;
      }
    }
  };

  const loadScriptFunc = () => {
    dispatch(
      setScript({
        isLoaded: true,
        src: config.scriptBotLink
      })
    );
    showWidget();
    setLoading(true);
    let inter = setInterval(() => {
      console.log('connecting...');
      if (window.ApexChat) {
        initializeWidgetOnClick();
        setLoading(false);
        clearInterval(inter);
      } else if (!window.navigator.onLine) clearInterval(inter);
    }, 1000);
  };

  const chatCloseFunction = () => {
    widgetVisibility(ElementIdWidgetButton, false);
    widgetVisibility(ElementIdPreChat, false);
    setShowChat(false);
    storage.setItem('ScriptLoad', false);
  };

  const initializeWidgetOnClick = () => {
    initializeChatCloseOnClick();
    let iframeWidget = document.getElementById(IFramIdWidget);
    if (iframeWidget) {
      let iframeDoc = (
        iframeWidget.contentWindow || iframeWidget.contentDocument
      ).document;
      let widgetButton = null;
      let inter2 = setInterval(() => {
        console.log('initializing...');
        widgetButton = iframeDoc.getElementById(ElementIdWidgetButton);
        if (widgetButton) {
          widgetButton.onclick = onWidgetClickFunction;
          clearInterval(inter2);
        } else if (!window.navigator.onLine) clearInterval(inter2);
      }, 1000);
    }
  };

  const onWidgetClickFunction = () => {
    setTimeout(() => {
      initializeChatCloseOnClick();
    }, 200);
  };

  const addScript = () => {
    if (!showChat) {
      if (scriptLoad) {
        initializeWidgetOnClick();
        showWidget();
        storage.setItem('ScriptLoad', true);
      } else if (window.navigator.onLine) {
        loadScriptFunc();
      }
    } else {
      setShowChat(false);
    }
  };
  const widgetVisibility = (id, condition) => {
    setTimeout(() => {
      let doc = document;
      if (!doc.getElementById(id)) {
        let iframeWidget = document.getElementById(IFramIdWidget);
        if (iframeWidget) {
          let iframeDoc = (
            iframeWidget.contentWindow || iframeWidget.contentDocument
          ).document;
          doc = iframeDoc;
        }
      }
      if (doc.getElementById(id))
        doc.getElementById(id).style.visibility = condition
          ? 'visible'
          : 'hidden';
    }, 50);
  };

  const onLogOutRemoveChat = () => {
    widgetVisibility(MainDivChatWindow, false);
    widgetVisibility(IFramIdChatBox, false);
    widgetVisibility(ElementIdPreChat, false);
    setShowChat(false);
  };

  return (
    <div className={classes.topContainer}>
      {screen?.xs && (
        <UilBars
          size="30"
          color="var(--primary)"
          className="ml-2"
          onClick={() => setShowSidebar(!showSidebar)}
        />
      )}
      <Link className={`navbar-logo d-block ${classes.logoLink}`} to="/home">
        <Logo />
      </Link>
      <div className={`align-items-center ${classes.topbarMenuContainer}`}>
        <HelpComp loading={loading} addScript={addScript} />
        <ProfileNav
          showSideBar={false}
          onLogOutRemoveChat={onLogOutRemoveChat}
        />
      </div>
    </div>
  );
};

export default TopBar;
