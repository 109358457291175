import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import { mapServerValuesToInitialValues } from 'helpers/generic.methods';
import { toast } from 'react-toastify';
import { contactInformation } from 'helpers/schemas/companySchemas/initialValues';
import { contactInformationSchemas } from 'helpers/schemas/companySchemas/schemas';
import { useFormik } from 'formik';
import { success } from 'helpers/variables';
import {
  updateCompany,
  updateCompanyToState
} from 'features/companies/companiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DropDownEditable from 'helpers/ui-controls/DropDownEditable';
import dropdownEnums, { Enums } from 'helpers/dropdown-enums';
import { selectRoles } from 'features/roles/rolesSlice';

const ContactInformation = ({ company, permissionEdit, clickHandlerMenu }) => {
  const [companyObj, setCompanyObj] = useState({ ...contactInformation });
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const companyId = company.id;
  const [updatedCompanyData, setUpdatedDataCompany] = useState({});
  const { roles } = useSelector(selectRoles);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  useEffect(() => {
    mapServerValuesToInitialValues(company, contactInformation)
      .then(data => {
        setCompanyObj({ ...contactInformation, ...data });
      })
      .catch(err => {
        throw new Error(err);
      });
    dispatch(updateCompanyToState({ id: companyId, data: updatedCompanyData }));

    return () => {
      // clickHandlerMenu();
    };
  }, [company, updatedCompanyData]);

  const companyFormHandler = async formData => {
    try {
      setLoading(true);
      let payload = {
        ...formData,
        id: company.id,
        companyKey: company.companyKey
      };
      const response = await updateCompany(payload);
      setUpdatedDataCompany(response?.data?.data);
      toast.success(success.saveSuccess('Contact information'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(error || error.message);
      setLoading(false);
    }
  };

  /** Init Formik */
  const CompanyFormIk = useFormik({
    enableReinitialize: true,
    initialValues: companyObj,
    validationSchema: contactInformationSchemas,
    onSubmit: companyFormHandler
  });

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    CompanyFormIk.values[key] = value;
    CompanyFormIk.handleChange(element);
  };

  return (
    <>
      <Form
        className="form"
        onSubmit={CompanyFormIk.handleSubmit}
        autoComplete="off"
      >
        <div className="page-section">
          <ToggleMenuButton
            heading={t('company.contactInformation.heading1')}
            clickHandlerMenu={clickHandlerMenu}
          />
          <section>
            <SectionHeader
              sectionHeading={t('company.contactInformation.heading2')}
              loading={loading}
              // disabled={loading || !CompanyFormIk.dirty}
              disabled={!permissionEdit}
              editable={editable}
              handleEditableState={setEditable}
            />
            <div className="section-body">
              <ul className="editable-fields-lists">
                <SimpleLabelTextBox
                  heading={t(
                    'company.contactInformation.primaryContactName.label'
                  )}
                  field="primaryContactName"
                  dataProperty={CompanyFormIk.values['primaryContactName']}
                  error={CompanyFormIk.errors['primaryContactName']}
                  titleText={t(
                    'company.contactInformation.primaryContactName.info'
                  )}
                  editable={editable}
                  readOnly={false}
                  handleChange={handleOnChange}
                />
                <SimpleLabelTextBox
                  heading={t(
                    'company.contactInformation.primaryContactEmail.label'
                  )}
                  field="primaryContactEmail"
                  dataProperty={CompanyFormIk.values['primaryContactEmail']}
                  titleText={t(
                    'company.contactInformation.primaryContactEmail.info'
                  )}
                  error={CompanyFormIk.errors['primaryContactEmail']}
                  editable={editable}
                  handleChange={handleOnChange}
                />

                <SimpleLabelTextBox
                  heading={t(
                    'company.contactInformation.primaryContactPhone.label'
                  )}
                  field="primaryContactPhone"
                  dataProperty={CompanyFormIk.values['primaryContactPhone']}
                  error={CompanyFormIk.errors['primaryContactPhone']}
                  editable={editable}
                  handleChange={handleOnChange}
                  titleText={t(
                    'company.contactInformation.primaryContactPhone.info'
                  )}
                />

                <SimpleLabelTextBox
                  heading={t(
                    'company.contactInformation.primaryContactAltPhone.label'
                  )}
                  field="primaryContactAltPhone"
                  dataProperty={CompanyFormIk.values['primaryContactAltPhone']}
                  error={CompanyFormIk.errors['primaryContactAltPhone']}
                  editable={editable}
                  handleChange={handleOnChange}
                  titleText={t(
                    'company.contactInformation.primaryContactAltPhone.info'
                  )}
                />
                {roles.company.specialFields.invoiceEmail.view && (
                  <SimpleLabelTextBox
                    heading={t('company.contactInformation.invoiceEmail.label')}
                    field="invoiceEmail"
                    dataProperty={CompanyFormIk.values['invoiceEmail']}
                    error={CompanyFormIk.errors['invoiceEmail']}
                    editable={
                      editable && roles.company.specialFields.invoiceEmail.edit
                    }
                    handleChange={handleOnChange}
                    titleText={t(
                      'company.contactInformation.invoiceEmail.info'
                    )}
                  />
                )}
                <SimpleLabelTextBox
                  heading={t('company.contactInformation.address.label')}
                  field="address"
                  dataProperty={CompanyFormIk.values['address']}
                  error={CompanyFormIk.errors['address']}
                  editable={editable}
                  handleChange={handleOnChange}
                  titleText={t('company.contactInformation.address.info')}
                />
                <SimpleLabelTextBox
                  heading={t('company.contactInformation.city.label')}
                  field="city"
                  dataProperty={CompanyFormIk.values['city']}
                  error={CompanyFormIk.errors['city']}
                  editable={editable}
                  handleChange={handleOnChange}
                  titleText={t('company.contactInformation.city.info')}
                />

                <SimpleLabelTextBox
                  heading={t('company.contactInformation.state.label')}
                  field="state"
                  dataProperty={CompanyFormIk.values['state']}
                  error={CompanyFormIk.errors['state']}
                  editable={editable}
                  handleChange={handleOnChange}
                  titleText={t('company.contactInformation.state.info')}
                />
                <SimpleLabelTextBox
                  heading={t('company.contactInformation.zip.label')}
                  field="zip"
                  dataProperty={CompanyFormIk.values['zip']}
                  error={CompanyFormIk.errors['zip']}
                  editable={editable}
                  handleChange={handleOnChange}
                  titleText={t('company.contactInformation.zip.info')}
                />

                <SimpleLabelTextBox
                  heading={t('company.contactInformation.serviceArea.label')}
                  field="serviceArea"
                  dataProperty={CompanyFormIk.values['serviceArea']}
                  error={CompanyFormIk.errors['serviceArea']}
                  editable={editable}
                  handleChange={handleOnChange}
                  titleText={t('company.contactInformation.serviceArea.info')}
                />
                <DropDownEditable
                  heading={t('company.contactInformation.country.label')}
                  field="country"
                  dataProperty={CompanyFormIk.values['country']}
                  error={CompanyFormIk.errors['country']}
                  editable={editable}
                  handleChange={handleOnChange}
                  titleText={t('company.contactInformation.country.info')}
                  materialFields
                  options={dropdownEnums.enum_Country}
                />
              </ul>
            </div>
          </section>
        </div>
      </Form>
    </>
  );
};

export default ContactInformation;
