import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { pricingDetails } from 'helpers/schemas/provisionSchemas/initialValues';
import { pricingDetailSchemas } from 'helpers/schemas/provisionSchemas/schemas';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import { mapServerValuesToInitialValues } from 'helpers/generic.methods';
import { toast } from 'react-toastify';
import {
  UpdateProvision,
  updateProvisionToState
} from 'features/provisioning/ProvisioningSlice';
import { useDispatch } from 'react-redux';

import { success } from 'helpers/variables';
const PricingDetails = ({ formData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [formValues, setFormValues] = useState(pricingDetails);
  const [loading, setLoading] = useState(false);
  const [id, setProvisionId] = useState('');

  const ProvisionFormHandler = async data => {
    try {
      setLoading(true);
      let payload = {
        ...data,
        id: id
      };
      const response = await UpdateProvision(payload);
      dispatch(updateProvisionToState(response?.data?.data));
      toast.success(success.saveSuccess('Onboarding'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(error || error.message);
      setLoading(false);
    }
  };

  const PricingFormIk = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    validationSchema: pricingDetailSchemas,
    onSubmit: ProvisionFormHandler
  });

  useEffect(() => {
    setProvisionId(formData.id);
    mapServerValuesToInitialValues(formData, pricingDetails)
      .then(data => {
        setFormValues({ ...pricingDetails, ...data });
      })
      .catch(err => {
        throw new Error(err);
      });
  }, [formData]);

  const handleOnChange = (element, key, value) => {
    PricingFormIk.values[key] = value;
    PricingFormIk.handleChange(element);
  };

  return (
    <Form
      className="form"
      onSubmit={PricingFormIk.handleSubmit}
      autoComplete="off"
    >
      <SectionHeader
        sectionHeading={t('company.provisioningDetails.heading2')}
        loading={loading}
        editable={editable}
        handleEditableState={setEditable}
      />
      <section>
        <div className="section-body">
          <ul className="editable-fields-lists">
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.pricing.label')}
              field="pricing"
              dataProperty={PricingFormIk.values['pricing']}
              titleText={t('company.provisioningDetails.pricing.info')}
              editable={editable}
              handleChange={handleOnChange}
            />
            <ToggleToolTip
              heading={t('company.provisioningDetails.setupFee.label')}
              toolTipFor="setupFee"
              flag={PricingFormIk.values?.hasSetupFee}
              titleText={t('company.provisioningDetails.setupFee.info')}
              editable={editable}
              handleChange={handleOnChange}
              field="hasSetupFee"
            />
            {/* <ToggleToolTip
              heading={t('company.provisioningDetails.smsFee.label')}
              toolTipFor="smsFee"
              flag={PricingFormIk.values?.hasSmsFee}
              titleText={t('company.provisioningDetails.smsFee.info')}
              editable={editable}
              handleChange={handleOnChange}
              field="hasSmsFee"
            /> */}
            {/* <ToggleToolTip
              heading={t('company.provisioningDetails.exitPopupFee.label')}
              toolTipFor="exitPopupFee"
              flag={PricingFormIk.values?.hasExitPopUpFee}
              titleText={t('company.provisioningDetails.exitPopupFee.info')}
              editable={editable}
              handleChange={handleOnChange}
              field="hasExitPopUpFee"
            /> */}
            {/* <ToggleToolTip
              heading={t('company.provisioningDetails.facebookFee.label')}
              toolTipFor="facebookFee"
              flag={PricingFormIk.values?.hasFacebookFee}
              titleText={t('company.provisioningDetails.facebookFee.info')}
              editable={editable}
              handleChange={handleOnChange}
              field="hasFacebookFee"
            /> */}
            {/* <ToggleToolTip
              heading={t('company.provisioningDetails.spanishFee.label')}
              toolTipFor="spanishFee"
              flag={PricingFormIk.values?.hasSpanishFee}
              titleText={t('company.provisioningDetails.spanishFee.info')}
              editable={editable}
              handleChange={handleOnChange}
              field="hasSpanishFee"
            /> */}
            <ToggleToolTip
              heading={t(
                'company.provisioningDetails.sendDealerCRMEmail.label'
              )}
              field="useCrmEmail"
              toolTipFor="sendDealerCRMEmail"
              flag={PricingFormIk.values?.useCrmEmail}
              titleText={t(
                'company.provisioningDetails.sendDealerCRMEmail.info'
              )}
              editable={editable}
              handleChange={handleOnChange}
            />
            {PricingFormIk.values?.useCrmEmail && (
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.autoDealerCRMEmail.label'
                )}
                field="autoDealerCRMEmail"
                dataProperty={PricingFormIk.values['autoDealerCRMEmail']}
                titleText={t(
                  'company.provisioningDetails.autoDealerCRMEmail.info'
                )}
                error={
                  PricingFormIk.touched.autoDealerCRMEmail &&
                  PricingFormIk.errors['autoDealerCRMEmail']
                }
                editable={editable}
                handleChange={handleOnChange}
                handleBlur={PricingFormIk.handleBlur}
              />
            )}

            <ToggleToolTip
              heading={t('company.provisioningDetails.freeConsultation.label')}
              toolTipFor="freeConsultation"
              flag={PricingFormIk.values?.freeConsultation}
              titleText={t('company.provisioningDetails.freeConsultation.info')}
              editable={editable}
              handleChange={handleOnChange}
              field="freeConsultation"
            />
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.consultationFee.label')}
              field="consultationFee"
              dataProperty={PricingFormIk.values['consultationFee']}
              titleText={t('company.provisioningDetails.consultationFee.info')}
              editable={editable}
              handleChange={handleOnChange}
            />
          </ul>
        </div>
      </section>
    </Form>
  );
};

export default PricingDetails;
