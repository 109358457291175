import React from 'react';
import SSO_Redirection from 'app/redirection-SSO';
import { UilExchange } from '@iconscout/react-unicons';
import './redirectComp.scss';
import PopMenu from './popmenu';

const RedirectComp = ({ showSideBar }) => {
  const redirectHandler = () => {
    // redirection here..
    SSO_Redirection();
  };

  return (
    <>
      <PopMenu
        id="introJS-item-switch"
        showSideBar={showSideBar}
        title={'Switch'}
        toolTipText={'Switch'}
        menuHeading={'Switch'}
        showTooltip={false}
        icon={<UilExchange size="24" className="sidebar-link" />}
        // clickHandler={e => history.push('/portal/settings/widget-editor')}
      >
        {() => (
          <li
            className="notification-list-item option cursor-pointer"
            onClick={redirectHandler}
          >
            Existing Portal
          </li>
        )}
      </PopMenu>
    </>
  );
};

export default RedirectComp;
