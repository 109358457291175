export const en_provisioningDetails = {
  heading1: 'Onboarding details',
  heading2: 'Pricing',
  heading3: 'Business operations',
  heading4: 'Attachments',
  heading5: 'Basic Details',
  heading6: 'Features',
  saveOnboarding: 'Save Onboarding',
  saveOnboardingWarningMsg:
    'You have not selected any feature for this provisioning request. Do you wish to proceed?',
  featureInfo: {
    info: 'This is the {{description}} fee at {{amount}} charged {{paymentType}}'
  },
  companyName: {
    label: 'Company name',
    info: 'The name of the company.'
  },
  fileName: {
    label: 'File Name',
    info: ''
  },
  downloadFile: {
    label: 'Download',
    info: ''
  },
  companyKeyRequest: {
    label: 'Company key request',
    info: 'Use this field for the suggested Company Key.'
  },
  status: {
    label: 'Status',
    info: 'What step you are within the onboarding request.'
  },
  partnerRepresentativeNameEmail: {
    label: 'Partner representative name email',
    info: 'Insert the name(s) who will receive email communication and main point of contact for the account.'
  },
  apexchatRepresentativeEmail: {
    label: 'Apexchat representative email',
    info: 'Insert the email(s) who will receive email communication from the onboarding and main point of contact for the account.'
  },
  leadEmails: {
    label: 'Lead emails',
    info: 'The email receiepents who will receive the HTML lead.'
  },
  website: {
    label: 'Website',
    info: 'The website of the business.'
  },
  googleAnalyticsCode: {
    label: 'Google analytics code',
    info: 'Account code for Google Analytics.'
  },
  crm: {
    label: 'CRM',
    info: 'Select the CRM the client wants to integrate.'
  },
  crmEmail: {
    label: 'CRM Email',
    info: 'Select the CRM the client wants to integrate.'
  },
  setupInstructions: {
    label: 'Setup instructions',
    info: 'Use this field for any additional information that may be relevant.'
  },
  attachements: {
    label: 'Attachments',
    info: 'Provide necessary attachments (logo, agent, document, etc.)'
  },
  companyKey: {
    label: 'Company key',
    info: 'Insert a company key for the business (body of website url).'
  },
  businessCategory: {
    label: 'Business category',
    info: 'Select which industry best categorizes the business.'
  },
  subCategories: {
    label: 'Sub categories',
    info: 'Select a sub category for the business if an option is available.'
  },
  pricing: {
    label: 'Pricing',
    info: 'Provide pricing information for the billing department.'
  },
  setupFee: {
    label: 'Setup fee?',
    info: 'Enable the Setup fee if applicable.'
  },
  smsFee: {
    label: 'SMS fee?',
    info: 'Enable if the SMS feature is required.'
  },
  exitPopupFee: {
    label: 'Exit popup fee?',
    info: 'Enable if the Exit Pop-up feature is required.'
  },
  facebookFee: {
    label: 'Facebook fee?',
    info: 'Enable if the Facebook feature is required.'
  },
  spanishFee: {
    label: 'Spanish fee?',
    info: 'Enable if the business requires Spanish agents.'
  },
  autoDealerCRMEmail: {
    label: 'Auto dealer CRM email',
    info: 'Insert email(s) for the auto dealer CRM email.'
  },
  sendDealerCRMEmail: {
    label: 'Send dealer CRM email',
    info: 'Enable if the Auto deal CRM email feature is required.'
  },
  freeConsultation: {
    label: 'Free consultation',
    info: 'Enable if a free consultation is available.'
  },
  consultationFee: {
    label: 'Consultation fee',
    info: 'Please provide the consultation fee or useful information that may help regarding pricing.'
  },
  openAccount: {
    label: 'Open account',
    info: 'Open account'
  },
  // ==============
  areaOfCoverage: {
    label: 'Area of coverage',
    info: 'The coverage area for the business.'
  },
  linkToPrivacyPolicy: {
    label: 'Link to privacy policy',
    info: "A link to the company's privacy policy."
  },
  scriptTitlePosition: {
    label: 'Script title position',
    info: 'Title of position (in the script) of the person who will contact the visitor after the chat. EXAMPLE: Representative, Consultant, Supervisor, etc.'
  },
  hipaa: {
    label: 'HIPPA',
    info: 'Check this box if the company requires HIPPA.'
  },
  languages: {
    label: 'Language support',
    info: "Specify the languages requested for this company's scripts and agent support."
  },
  callConnect: {
    label: 'Call connect',
    info: 'If set, the agents will connect the visitor and business with a phone call during the live chat within a specific set of hours.'
  },
  callConnectPhone: {
    label: 'Call connect number',
    info: 'Insert the business phone number of the business. The business will be available to answer these phone calls during a specific set of hours.'
  },
  callConnectTranscript: {
    label: 'Call connect transcript',
    info: 'If set, the "Call Connect" button will automatically send the visitor transcript when the initial connect succeeds.'
  },
  callConnectHours: {
    label: 'Call connect hours',
    info: 'The call connect hours for the business.'
  },
  insuranceCoverageResponse: {
    label: 'Insurance coverage response',
    info: 'If applicable, specific information that should be given about insurance coverage.'
  },
  specialInstructions: {
    label: 'Special instructions',
    info: 'Place any special instructions that will help the onboarding team set up the account.'
  },
  officeHoursTimezone: {
    label: 'Time Zone',
    info: 'The Time Zone of the business.'
  },
  createdOn: {
    label: 'Created on',
    info: 'When the company was created within the system.'
  },
  modifiedOn: {
    label: 'Modified on',
    info: 'The last date the company was modified.'
  },
  businessHours: {
    label: 'Business hours',
    info: 'The hours of operations for the business.'
  },
  officeHours: {
    label: 'Office hours',
    info: 'The hours of operations for office.'
  },
  quickConnectHours: {
    label: 'Call Connect hours',
    info: 'The hours of operations for Call Connect.'
  },
  billingEmailAddress: {
    label: 'Billing Contact email',
    info: 'The email receiepents who will receive the billing information.'
  }
};
export default en_provisioningDetails;
export { default as fr_provisioningDetails } from '../fr-FR-ProvisioningDictionary/fr-provisioningDetails';
export { default as sp_provisioningDetails } from '../sp-SP-ProvisioningDictionary/sp-provisioningDetails';
