import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import { facebookPixelSectionSchema } from 'helpers/schemas/companySchemas/schemas';
import { success } from 'helpers/variables';
import { useTranslation } from 'react-i18next';
import { facebookPixelSection } from 'helpers/schemas/companySchemas/initialValues';
import {
  mapServerValuesToInitialValues,
  setupTrackingPayload
} from 'helpers/generic.methods';
import {
  updateCompany,
  updateCompanyToState
} from 'features/companies/companiesSlice';
import { useDispatch } from 'react-redux';
import { Enums } from 'helpers/dropdown-enums';

const FacebookPixelTracking = ({
  company,
  trackingFlags,
  permissionEdit,
  reflectObject
}) => {
  const { t } = useTranslation();
  const [facebookPixel_chk, setFacebookPixel_chk] = useState(
    trackingFlags.facebookPixelTracking
  );
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [facebookPixelSectionObj, setFacebookPixelSectionObj] = useState({
    ...facebookPixelSection
  });
  const companyId = company.id;
  const [updatedCompanyData, setUpdatedDataCompany] = useState({});

  const dispatch = useDispatch();
  const trackingFormHandler = async formData => {
    try {
      formData.companyTrackingTypes = setupTrackingPayload(
        company.id,
        facebookPixel_chk,
        company.companyTrackingTypes,
        Enums.AnalyticsTracking.FacebookPixel
      );
      setLoading(true);
      let payload = {
        ...formData,
        id: company.id,
        companyKey: company.companyKey
      };
      const response = await updateCompany(payload);
      setUpdatedDataCompany(response?.data?.data);
      reflectObject(response?.data?.data);

      toast.success(success.saveSuccess('Facebook Pixel Tracking'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(success.saveSuccess(error));
      setLoading(false);
    }
  };
  /** Init Formik */
  const TrackingFormIk = useFormik({
    enableReinitialize: true,
    initialValues: facebookPixelSectionObj,
    validationSchema: facebookPixel_chk ? facebookPixelSectionSchema : null,
    onSubmit: trackingFormHandler
  });
  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    TrackingFormIk.values[key] = value;
    TrackingFormIk.handleChange(element);
  };
  /**
   * @handleFacebookPixelSwitch: callback function needs to send to headerComponent
   */
  const handleFacebookPixelSwitch = checked => {
    setFacebookPixel_chk(checked);
  };
  useEffect(() => {
    handleFacebookPixelSwitch(!!trackingFlags.facebookPixelTracking);
    mapServerValuesToInitialValues(company, facebookPixelSection)
      .then(data => {
        setFacebookPixelSectionObj({ ...facebookPixelSection, ...data });
      })
      .catch(err => {
        throw new Error(err);
      });
    dispatch(updateCompanyToState({ id: companyId, data: updatedCompanyData }));
  }, [trackingFlags, company, updatedCompanyData, companyId, dispatch]);

  return (
    <Form
      className="form"
      onSubmit={TrackingFormIk.handleSubmit}
      autoComplete="off"
    >
      <section>
        <SectionHeader
          sectionHeading={t('company.trackingIntegration.heading3')}
          field="facebookPixelTracking"
          id={'facebookPixelTracking' + companyId}
          loading={loading}
          // disabled={loading || !TrackingFormIk.dirty}
          disabled={loading || !permissionEdit}
          error={TrackingFormIk.errors['facebookPixelTracking']}
          editable={editable}
          handleEditableState={setEditable}
          hasHeaderProperty={true}
          headerProperty={facebookPixel_chk}
          headerPropertyOnChange={handleFacebookPixelSwitch}
        />
        <div className="section-body">
          {facebookPixel_chk ? (
            <ul
              className="editable-fields-lists"
              // style={!!facebookPixel_chk ? {} : { display: 'none' }}
            >
              <SimpleLabelTextBox
                heading={t('company.trackingIntegration.accountCode.label')} //"Account Code for Facebook"
                field="facebookPixelAccount"
                id={'facebookPixelAccount' + companyId}
                dataProperty={TrackingFormIk.values['facebookPixelAccount']}
                error={TrackingFormIk.errors['facebookPixelAccount']}
                titleText={t('company.trackingIntegration.accountCode.info')}
                helperText={t('company.trackingIntegration.accountCode.info')} //"Account Code for Facebook"
                editable={editable}
                handleChange={handleOnChange}
              />
            </ul>
          ) : null}
        </div>
      </section>
    </Form>
  );
};
export default FacebookPixelTracking;
