import { createSlice } from '@reduxjs/toolkit';
import storage from 'helpers/storage';
import ApiServices from 'api/apexchat.api.services';
import {
  LOGIN,
  RESETPASSWORD,
  CHANGEPASSWORD,
  JWT_VERIFY,
  HostAPI
} from 'api/endpoints';
import {
  getCookie,
  removeFromCookies,
  removeKeysFromLS
} from 'helpers/generic.methods';
import { setCookie } from 'helpers/generic.methods';
import SSO_Redirection from 'app/redirection-SSO';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/

const removeChatbot = () => {
  var chatbot = document.getElementById('apexchat_prechat_invitation_wrapper');
  if (chatbot) chatbot.style.display = 'none';
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    profile: undefined,
    isAuthenticated: !!getCookie('Auth'),
    dynamicMeta: null
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    login: (state, action) => {
      let loginUser = JSON.parse(storage.getItem('ViewedUser'));
      if (!loginUser) {
        storage.setItem('ViewedUser', [
          {
            isLogginBefore: false,
            userId: action.payload.UserID
          }
        ]);
      } else {
        for (let i = 0; i < loginUser.length; i++) {
          if (loginUser[i].userId === action.payload.UserID) {
            storage.setItem('ViewedUser', loginUser);
            break;
          } else {
            storage.setItem('ViewedUser', [
              ...loginUser,
              {
                isLogginBefore: false,
                userId: action.payload.UserID
              }
            ]);
          }
        }
      }

      if (action.payload.NewPortalEnabled) {
        setCookie('Auth', action.payload.JWT, 3);
        storage.setItem('Profile', action.payload);
        state.isAuthenticated = !!action.payload.JWT;
        state.profile = action.payload;
      } else {
        SSO_Redirection({
          authToken: action.payload.JWT,
          shouldOpenInSameWindow: true,
          profile: action.payload
        });
      }
      delete action.payload.JWT;
    },
    logout: (state, action) => {
      let loginUser = JSON.parse(storage.getItem('ViewedUser'));
      let profile = JSON.parse(storage.getItem('Profile'));
      if (loginUser && profile) {
        const objIndex = loginUser.findIndex(
          obj => obj.userId === profile && profile.UserID
        );
        if (objIndex >= 0) {
          loginUser[objIndex].isLogginBefore = true;
          storage.setItem('ViewedUser', loginUser);
        }
      }

      let keysArray = [
        'Auth',
        'Profile',
        'companies-selectedTab',
        'companies-selectedCompanies',
        'companies-widgetSetting',
        'chats-selectedTab',
        'chats-selectedChats',
        'leads-selectedLeads',
        'leads-selectedTab',
        'provisions-selectedProvisions',
        'provisions-selectedTab',
        'myReports-selectedMyReports',
        'myReports-selectedTab',
        'queuedReports-selectedQueuedReports',
        'queuedReports-selectedTab',
        'language',
        'return-url',
        'ScriptLoad',
        'analytics-selected-filters',
        'analyticsChartsHideShow',
        'analytics-quickstats-widget',
        'duration',
        'incoming_chat_available',
        'MD_visitorCountry',
        'Tour'
      ];
      delete ApiServices.instance.defaults.headers.common['Authorization'];
      removeKeysFromLS(keysArray);
      removeFromCookies([
        'Auth',
        'QuickStats',
        'ActivationStats',
        'LeadGenerators',
        'livechat_has_windowed_chat_started',
        'analyticsChartsGraphs',
        'analyticsBusinessOptions',
        'topInactiveBillers',
        'budget/Pacing',
        'agentImagesCTR',
        'accountsActivation',
        'accounts',
        'chatReferrals'
      ]);
      removeChatbot();
      state.isAuthenticated = false;
      state.profile = undefined;
      state.dynamicMeta = null;
      if (getCookie('livechat_chat_id')) {
        setCookie('livechat_chat_id', '', 0);
        window.location.reload(false);
      }
    },
    setProfileToState: state => {
      let profile = storage.getItem('Profile');
      if (profile) state.profile = JSON.parse(profile);
    },
    setDynamicMetaToState: (state, action) => {
      state.dynamicMeta = action.payload;
    }
  }
});

export const { login, logout, setProfileToState, setDynamicMetaToState } =
  authSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const loginAsync = payload => async dispatch => {
  delete payload.rememberMe;
  return ApiServices.get(LOGIN, payload)
    .then(async ({ data }) => {
      if (data?.success && data?.data?.JWT) {
        await dispatch(login(data.data));
        return Promise.resolve('resolved');
      }
      return Promise.reject('rejected');
    })
    .catch(error => {
      return Promise.reject('rejected');
    });
};

export const forgotPasswordAsync = payload => async dispatch => {
  let resetPwEndPoint = RESETPASSWORD(payload);
  return ApiServices.put(resetPwEndPoint, payload)
    .then(async ({ data }) => {
      if (data.success) {
        return Promise.resolve(data.data);
      }
      return Promise.reject('rejected');
    })
    .catch(error => {
      return Promise.reject('rejected');
    });
};

/**
 * These function allows to perform async logic for changepassword
 * It gets username from profile in storage
 * Assigning require password rest false after success
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const changePasswordAsync = payload => async dispatch => {
  let profile = storage.getItem('Profile');
  profile = profile && JSON.parse(profile);
  // setting some prerequisite for the API endpoint
  // {
  //   "userName": "string",
  //   "currentPassword": "string",
  //   "newPassword": "string",
  //   "requirePasswordReset": boolean
  // }
  payload.currentPassword = payload.oldPassword;
  payload.userName = profile.Username;
  payload.requirePasswordReset = false;
  let changePwEndPoint = CHANGEPASSWORD(payload);
  return ApiServices.put(changePwEndPoint, payload)
    .then(async ({ data }) => {
      if (data.success) {
        profile.RequirePasswordReset = false;
        storage.setItem('Profile', profile);
        return Promise.resolve(data.data);
      }
      return Promise.reject('rejected');
    })
    .catch(error => {
      return Promise.reject('rejected');
    });
};
/* called a selector and allows to select values from the state */
export const selectLogin = state => {
  return state.auth;
};

export const verifyJwt = payload => {
  let jwtVerifyAPI = `${JWT_VERIFY}?jwt=${payload}`;
  return ApiServices.post(jwtVerifyAPI, {});
};

export const getAppMetaAsync = payload => {
  return ApiServices.getWithParams(HostAPI.getMeta(payload?.data));
};

export const selectDynamicMeta = state => {
  return state.auth.dynamicMeta;
};

export default authSlice.reducer;
