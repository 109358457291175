import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { dayAgo, truncateText } from 'helpers/generic.methods';
import TypingIndicator from '../TypingIndicator';
import ActionButtons from './ActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChatId,
  getSelectedChatLastMessage,
  getSelectedChatStarted,
  getSelectedChatTypingIndicator,
  getShelvedChatList,
  getVisitorInfoName,
  removeChatFromShelved
} from 'features/messagingChats/messagingChats';
import { Badge } from 'react-bootstrap';
import { getUnreadMsgsCountByChatId } from 'features/messagingChats/messagingChats';
import './index.scss';
import { getPlainStringFromHTMLElementAsString } from 'helpers/validate';
import { Enums } from 'helpers/dropdown-enums';
import IdleCounter from './IdleCounter';
import { showTypingIndicatorInChat } from '../Helper';
import Title from './title';
import TruncateWithToolTip from 'components/TruncateWithToolTip';
import { chatInitiatedByPlatformObj } from 'helpers/messaging/message.methods';
import { ReactComponent as FacebookIcon } from 'static/images/chat-icons/Facebook.svg';
import { ReactComponent as WebIcon } from 'static/images/chat-icons/Web.svg';
import { ReactComponent as SMSIcon } from 'static/images/chat-icons/SMS.svg';
import { ReactComponent as GoogleIcon } from 'static/images/chat-icons/Google.svg';
import { Dot } from '../UserInfo';

const initiatedByObj = { ...chatInitiatedByPlatformObj() };

const chatIcons = {
  Web: <WebIcon className="chat-icon" size={25} />,
  SMS: <SMSIcon className="chat-icon" size={25} />,
  Facebook: <FacebookIcon className="chat-icon" size={25} />,
  GMB: <GoogleIcon className="chat-icon" size={25} />
};

const ChatListItem = ({
  chat,
  onChatClickHandler,
  onAccept,
  onIgnore,
  onReject,
  showActionBtns,
  eventKey,
  roomType,
  isOperator = false,
  isAgent = false
}) => {
  const lastMessage = useSelector(getSelectedChatLastMessage);
  const isTyping = useSelector(getSelectedChatTypingIndicator);
  const isChatStarted = useSelector(getSelectedChatStarted);
  const selectedChatId = useSelector(getSelectedChatId);
  const getVisitorName = useSelector(getVisitorInfoName);
  const unreadMsgCount = useSelector(getUnreadMsgsCountByChatId);
  const shelvedChatList = useSelector(getShelvedChatList);
  let unreadCount = unreadMsgCount(chat.getId());
  let dispatch = useDispatch();
  const [chatInitialTime, setChatInitialTime] = useState('');

  const handleShelvedChatsOnChatEnded = chatId => {
    if (
      shelvedChatList.length &&
      shelvedChatList.some(chat => chat.getId() === chatId)
    ) {
      dispatch(removeChatFromShelved({ id: chatId }));
    }
  };

  const handleChatInitialTime = chat => {
    let lastMessageCreatedOn = lastMessage(chat.getId())?.createdOn;
    if (lastMessageCreatedOn) {
      setChatInitialTime(dayAgo(lastMessageCreatedOn));
    } else {
      setChatInitialTime(dayAgo(chat.getData().createdOn));
    }
  };

  // const ChatIdSpan = ({ chat }) => (
  //   <span className="copy-button">
  //     <CopyButton
  //       copySpecificText={`${chat.getId()} - ${chat
  //         ?.getLanguage()
  //         ?.toUpperCase()} - ${chat?.getCompanyName()}`}
  //       value={chat.getId()}
  //     />
  //   </span>
  // );

  const ChatIdAndLocationText = ({ chat, styles = {} }) => (
    <span style={styles}>
      <span className="">
        {chat?.getId()}
        {chat.getLocation() || chat?.data?.visitorInfoPhone ? (
          <Dot size="4" styles={{ backgroundColor: 'var(--darkgray-2)' }} />
        ) : (
          ''
        )}
      </span>
      <TruncateWithToolTip
        style={{ color: 'var(--darkgray-2)' }}
        text={`${
          isSmsChat
            ? `${chat?.data?.visitorInfoPhone}`
            : `${chat.getLocation()}`
        }`}
        limit={28}
      />
    </span>
  );

  // chat?.data?.isSms is checked for transferred chat or SMS chat;
  const isSmsChat =
    chat?.data?.isSms ||
    chat?.data?.isSMS ||
    initiatedByObj[chat?.data?.initiatedBy] === 'SMS';

  const getChatIcon = () => {
    let key = isSmsChat ? 'SMS' : initiatedByObj[chat?.data?.initiatedBy];
    return chatIcons[key];
  };

  return (
    <ListGroup.Item
      className={`chat-item m-2 ${
        selectedChatId === chat.getId() ? 'active-list' : 'non-active-list'
      }`}
      key={chat.getId()}
      onClick={() =>
        !showActionBtns ||
        // (showActionBtns && !chat?.data?.isTransferred) ||
        isChatStarted(chat.getId())
          ? onChatClickHandler(chat)
          : null
      }
    >
      <div className="left-section p-2">
        <div className="chatname">
          <span className="d-flex align-items-center position-relative">
            <div className="pr-1">{getChatIcon()}</div>
            &nbsp;
            <span className="w-100" style={{ lineHeight: '1' }}>
              <div className="d-flex justify-content-between">
                <Title
                  companyName={chat.getCompanyName()}
                  visitorName={getVisitorName(chat)}
                  isAgent={isAgent}
                  isOperator={isOperator}
                  chatSectionType={roomType}
                  chatInitiatedPlatform={isSmsChat ? 'SMS' : 'Web'}
                />
                {/* Idle timer badge */}
                <IdleCounter
                  chat={chat}
                  lastMessage={lastMessage}
                  handleShelvedChatsOnChatEnded={handleShelvedChatsOnChatEnded}
                  eventKey={eventKey}
                  handleChatInitialTime={handleChatInitialTime}
                />
              </div>

              <div
                className="d-flex align-items-center"
                style={{ marginTop: '2px' }}
              >
                {Number(roomType) !== Enums.RoomType.IncomingChat && (
                  <ChatIdAndLocationText chat={chat} />
                )}
              </div>
            </span>
          </span>
        </div>

        <div className="msg-time w-100 d-flex justify-content-between align-items-center mt-2">
          {Number(roomType) === Enums.RoomType.IncomingChat && (
            <ChatIdAndLocationText
              chat={chat}
              styles={{
                fontSize: '12px',
                alignSelf: 'end',
                color: 'var(--primary-2)'
              }}
            />
          )}
          {eventKey != 0 ? (
            <>
              <span className="text-left  message d-flex align-items-center">
                {unreadCount ? (
                  <Badge
                    pill
                    bg="secondary"
                    className="unread-msg-count rounded-circle mr-1"
                  >
                    {unreadCount}
                  </Badge>
                ) : null}
                {!isTyping(chat.getId()) ||
                !showTypingIndicatorInChat(chat?.data?.initiatedBy) ? (
                  truncateText(
                    getPlainStringFromHTMLElementAsString(
                      lastMessage(chat.getId()).text
                    ),
                    25
                  )
                ) : //  typing indicator will be show when visitor is typing
                showTypingIndicatorInChat(chat?.data?.initiatedBy) ? (
                  <TypingIndicator
                    msg={'typing'}
                    typing={isTyping(chat.getId())}
                    size={5}
                    containerStyles={{ marginRight: '12px' }}
                  />
                ) : null}
              </span>
              <span className="text-right date-time">{chatInitialTime}</span>
            </>
          ) : (
            /* button */
            showActionBtns &&
            !isChatStarted(chat.getId()) && (
              <ActionButtons
                chat={chat}
                // onAccept={onChatClickHandler}
                onAccept={
                  chat?.data?.isTransferred ? onAccept : onChatClickHandler
                }
                onIgnore={chat?.data?.isTransferred ? onIgnore : null}
                onReject={
                  !chat?.data?.isTransferred && !isAgent ? onReject : null
                }
                rejectLoading={chat?.data?.rejectLoading}
              />
            )
          )}
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default ChatListItem;
