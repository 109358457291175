import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import dropdownEnums from 'helpers/dropdown-enums';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import { useTranslation } from 'react-i18next';
import { basicDetails } from 'helpers/schemas/companySchemas/initialValues';
import { basicDetailsSchema } from 'helpers/schemas/companySchemas/schemas';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { mapServerValuesToInitialValues } from 'helpers/generic.methods';
import { success } from 'helpers/variables';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import {
  updateCompany,
  updateCompanyToState
} from 'features/companies/companiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import { selectRoles } from 'features/roles/rolesSlice';

const BasicDetails = ({ company, permissionEdit, clickHandlerMenu }) => {
  const [companyObj, setCompanyObj] = useState({ ...basicDetails });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const companyId = company.id;
  const [updatedCompanyData, setUpdatedDataCompany] = useState({});
  const { roles } = useSelector(selectRoles);
  const dispatch = useDispatch();
  useEffect(() => {
    mapServerValuesToInitialValues(company, basicDetails)
      .then(data => {
        setCompanyObj({ ...basicDetails, ...data });
      })
      .catch(err => {
        throw new Error(err);
      });
    dispatch(updateCompanyToState({ id: companyId, data: updatedCompanyData }));
  }, [company, updatedCompanyData]);

  const companyFormHandler = async formData => {
    try {
      setLoading(true);
      let payload = {
        ...formData,
        id: company.id,
        companyKey: company.companyKey
      };
      const response = await updateCompany(payload);
      setUpdatedDataCompany(response?.data?.data);
      toast.success(success.saveSuccess('Basic details'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(error || error.message);
      setLoading(false);
    }
  };

  /** Init Formik */
  const CompanyFormIk = useFormik({
    enableReinitialize: true,
    initialValues: companyObj,
    validationSchema: basicDetailsSchema,
    onSubmit: companyFormHandler
  });

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    CompanyFormIk.values[key] = value;
    CompanyFormIk.handleChange(element);
  };

  return (
    <Form
      className="form"
      onSubmit={CompanyFormIk.handleSubmit}
      autoComplete="off"
    >
      <div className="page-section">
        <ToggleMenuButton
          heading={t('company.basicDetails.heading1')}
          clickHandlerMenu={clickHandlerMenu}
        />
        <section>
          <SectionHeader
            sectionHeading={t('company.basicDetails.heading2')}
            loading={loading}
            // disabled={loading || !CompanyFormIk.dirty }
            disabled={!permissionEdit}
            editable={editable}
            handleEditableState={setEditable}
          />
          <div className="section-body">
            <ul className="editable-fields-lists">
              <SimpleLabelTextBox
                heading={t('company.basicDetails.companyId.label')}
                field="companyId"
                dataProperty={company['id']}
                titleText={t('company.basicDetails.companyId.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.basicDetails.hostName.label')}
                field="hostName"
                dataProperty={company['hostName']}
                titleText={t('company.basicDetails.hostName.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.basicDetails.relationship.label')}
                field="relationship"
                dataProperty={company.relationship}
                titleText={t('company.basicDetails.relationship.info')}
                dropdownFlag={true}
                dropdownEnum={dropdownEnums.enum_Relationship}
              />
              {roles.isAdminCompany ? (
                <ToggleToolTip
                  heading={t('company.basicDetails.CDN.label')}
                  toolTipFor="CDN"
                  flag={company.useCdn}
                  titleText={t('company.basicDetails.CDN.info')}
                />
              ) : null}
              <SimpleLabelTextBox
                heading={t('company.basicDetails.website.label')}
                field="domain"
                dataProperty={CompanyFormIk.values['domain']}
                titleText={t('company.basicDetails.website.info')}
                error={CompanyFormIk.errors['domain']}
                editable={editable}
                handleChange={handleOnChange}
              />
            </ul>
          </div>
        </section>
      </div>
      {roles.isAdminCompany ? (
        <div className="page-section">
          <section>
            <header className="section-head d-flex align-items-center justify-content-between">
              <h5 className="fw-600 mb-0">
                {t('company.basicDetails.heading3')}
              </h5>
            </header>
            <div className="section-body">
              <ul className="editable-fields-lists">
                <SimpleLabelTextBox
                  heading={t('company.basicDetails.languages.label')}
                  field="languages"
                  dataProperty={
                    company.languageSupport &&
                    company.languageSupport.join(', ')
                  }
                  titleText={t('company.basicDetails.languages.info')}
                />
              </ul>
            </div>
          </section>
        </div>
      ) : null}
    </Form>
  );
};

export default BasicDetails;
