import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { ProvisionAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const provisionSlice = createSlice({
  name: 'provision',
  initialState: {
    provision: [],
    refetchProvisionGrid: false
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/

    setProvisionToState: (state, action) => {
      state.provision = [...state.provision, action.payload.data];
    },

    setRefetchRequired: (state, action) => {
      state.refetchProvisionGrid = action.payload;
    },

    setAttachementToProvision: (state, action) => {
      let tempProvision = [...state.provision];
      let index = tempProvision.findIndex(com => com.id === action.payload.id);
      if (index !== -1) {
        tempProvision[index] = {
          ...tempProvision[index],
          fileAttachements: action.payload.attachements
        };
        state.provision = tempProvision;
      }
    },

    removeProvisionFromState: (state, action) => {
      let provision = [...state.provision];
      let index = provision.findIndex(com => com.id === action.payload.id);
      provision.splice(index, 1);
      state.provision = provision;
    },

    removeAllProvisionFromState: (state, action) => {
      state.provision = [];
    },

    updateProvisionToState: (state, action) => {
      let tempProvision = [...state.provision];
      let index = tempProvision.findIndex(com => com.id === action.payload.id);
      if (action.payload.id && index !== -1) {
        tempProvision[index] = action.payload;
        state.provision = tempProvision;
        state.refetchProvisionGrid = true;
      }
    },

    updateNewProvisionToState: (state, action) => {
      let tempProvision = [...state.provision];
      let index = tempProvision.findIndex(com => com.id === 0);
      if (action.payload.id && index !== -1) {
        tempProvision[index] = action.payload;
        state.provision = tempProvision;
      }
    }
  }
});

export const {
  setProvisionToState,
  updateNewProvisionToState,
  setRefetchRequired,
  setAttachementToProvision,
  removeProvisionFromState,
  removeAllProvisionFromState,
  updateProvisionToState
} = provisionSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const getProvisionAsync = payload => (dispatch, getState) => {
  // let provisionAPI = ProvisionAPI.get + '/' + payload.params.id;
  let provisionAPI = ProvisionAPI.get(payload.params.id);
  return ApiServices.get(provisionAPI)
    .then(async ({ data }) => {
      if (data?.success && data?.data) {
        dispatch(getAttachements(payload.params.id));
        await dispatch(setProvisionToState(data));
        return Promise.resolve(data);
      }
      return Promise.reject('rejected');
    })
    .catch(error => {
      return Promise.reject(error);
    });
  // }
};

export const getAttachements = id => dispatch => {
  let provisionAPI = ProvisionAPI.attachements + '?id=' + id;
  return ApiServices.get(provisionAPI)
    .then(async ({ data }) => {
      if (data?.success && data?.data) {
        await dispatch(
          setAttachementToProvision({ attachements: data.data, id })
        );
        return Promise.resolve(data);
      }
      return Promise.reject('rejected');
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const selectProvision = state => id => {
  let provision = state.provisions.provision;
  if (id && provision.length) {
    let temp = provision.filter(c => c.id === id);

    if (temp.length) {
      return { provision: temp[0] };
    } else {
      return { provision: {} };
    }
  } else {
    return { provision: {} };
  }
};
/* called a selector and allows to select values from the state */

export const getAllProvisionList = payload => {
  let provisionAPI = ProvisionAPI.getAll;
  return ApiServices.getWithParams(provisionAPI, { params: payload });
};

export const createNewProvision = payload => {
  let provisionAPI = ProvisionAPI.create;
  return ApiServices.post(provisionAPI, payload);
};

export const UpdateProvision = payload => {
  let provisionAPI = ProvisionAPI.update;
  return ApiServices.put(provisionAPI, payload);
};

export const SaveAttachements = (payload, id) => {
  let provisionAPI = ProvisionAPI.attachements + '?id=' + id;
  return ApiServices.postAttachements(provisionAPI, payload);
};

export const DeleteAttachement = (id, index) => {
  let provisionAPI = `${ProvisionAPI.attachements}/delete?id=${id}&index=${index}`;
  return ApiServices.post(provisionAPI);
};

export const getAllProvisions = state => {
  return state.provisions.provision;
};
export const exportAllProvisionsList = payload => {
  let provisionAPI = ProvisionAPI.export;
  return ApiServices.WCF_getWithParams(provisionAPI, { params: payload });
};

export const isRefetchRequired = state => {
  return state.provisions.refetchProvisionGrid;
};

export const getOfferedFeaturesByHostId = payload => {
  let provisionAPI = ProvisionAPI.getFeaturesByHostId(payload.params.id);
  return ApiServices.get(provisionAPI);
};

export const getOfferedFeaturesByProvisioningId = payload => {
  let provisionAPI = ProvisionAPI.getFeaturesByProvisioningId(
    payload.params.id
  );
  return ApiServices.get(provisionAPI);
};
export default provisionSlice.reducer;
